import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`Dumbbell Deadlifts 12-12-12\\@60% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`20:00 AMRAP of:`}</p>
    <p>{`100ft Bear Crawl`}</p>
    <p>{`6-DB Deadlifts (50’s/40’s)`}</p>
    <p>{`12-Wall Balls (20/14)`}</p>
    <p>{`24-Pushups`}</p>
    <p>{`100ft Burpee Broad Jump`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      